import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Utilities } from '../class/utilities';
import { Operation } from '../interface/operation';
import { OperationFunction } from '../shared/lists/operation-functions';
import { firstValueFrom } from 'rxjs';
import { DataService } from './data.service';

interface ApiOperation {
  _id?: string;
  description?: string,
  inOut?: boolean,
  available?: boolean,
  DRE: boolean,
  titleType?: string[], // required
  function?: OperationFunction,
  descriptionFunction?: string,
  financialGenera?: boolean,
  docFiscal?: boolean,
  docFiscalServico?: boolean,
  moveStock?: boolean,
  moveMediumCost?: boolean,
  opDefault?: boolean;
  priceReadjusts?: {
    available: boolean,
    mediumCost: boolean, // !lastCost
    lastCost: boolean, // !mediumCOst
  },
  ICMS?: {
    CST?: string,
    Message?: string,
    Aliquot?: number,
    CFOP?: string,
    CFOP_I?: string
  },
  PIS?: {
    CST?: string,
    Message?: string,
    Aliquot?: number,
  },
  COFINS?: {
    CST?: string,
    Message?: string,
    Aliquot?: number,
  },
  IPI?: string,
  superSimples?: number // aliquot,
  Message?: string,
  opercionEstadual: {
    naturalezaSubsTributariaCST10: string,
    naturalezaSubsTributariaCST60: string,
    naturalezaSTConsumidorCST10: string,
    naturalezaSTConsumidorCST60: string,
    naturalezaNFServicios: string,
    naturalezaCombLubrificante: string,
    naturalezaItemIndustrializado: string,
    naturalezaNaoContribuinte: string
  },
  opercionInterestadual: {
    naturalezaSubsTributariaCST10: string,
    naturalezaSubsTributariaCST60: string,
    naturalezaSTConsumidorCST10: string,
    naturalezaSTConsumidorCST60: string,
    naturalezaNFServicios: string,
    naturalezaCombLubrificante: string,
    naturalezaItemIndustrializado: string,
    naturalezaNaoContribuinte: string
  }
  expenseType?: string;
  createdAt?: string;
  updatedAt?: string;


  IRPJ?: {
    aliquotPart?: number
    aliquotLabor?: number
    valMinRet?: number
    msg?: string
}

INSS?: {
    aliquot?: number
    valMinRet?: number
    msg?: string
}

CSLL?: {
    aliquot?: number
    valMinRet?: number
    msg?: string
}
}

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  private _isFromMatrix = true;

  /** @todo set as Observable */
  operations: Operation[] = [];

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) { }

  // private async setOperations(){
  //   this.operations = await this.getAll();
  // }

  async getAll(): Promise<Operation[]> {
    const url = `${environment.mkgoURL}/api/v1/operation`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix))
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise()
    let operations = (resp["operations"] as ApiOperation[]).reverse();
    this.operations = operations.map(this.complyAPP);
    return this.operations;
  }

  async getById(id: string): Promise<Operation> {
    const url = `${environment.mkgoURL}/api/v1/operation/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix))
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return this.complyAPP(resp);
  }

  async register(operation: Operation): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/operation`;
    const apiOperation = this.complyAPI(operation);
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.post(url, apiOperation, header).pipe(first()).toPromise();
    return resp['id'];
  }

  async update(operation: Operation): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/operation/${operation.id}`;
    const apiOperation = this.complyAPI(operation);
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix))
    const resp: any = await this.http.put(url, apiOperation, header).pipe(first()).toPromise();
    return resp['id'];
  }

  async changeStatus(operationId: string, status: 0 | 1) {
    const url = `${environment.mkgoURL}/api/v1/operation/${operationId}/status/${status}`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.post(url, null, options).pipe(first()).toPromise();
    return resp;
  }

  private complyAPI(operation: Operation): ApiOperation {
    let apiObject: ApiOperation = {
      COFINS: operation.COFINS,
      ICMS: operation.ICMS,
      IPI: operation.IPI,
      PIS: operation.PIS,
      CSLL: operation.CSLL,
      INSS: operation.INSS,
      IRPJ: operation.IRPJ,
      DRE: operation.DRE,
      available: operation.available,
      description: operation.description,
      expenseType: operation.expenseType ? operation.expenseType._id : undefined,
      financialGenera: operation.generateFinancial,
      docFiscal: operation.docFiscal,
      docFiscalServico: operation.docFiscalServico,
      function: operation.operationFunction,
      descriptionFunction: operation.descriptionFunction,
      inOut: operation.entry,
      Message: operation.operationMessage,
      moveStock: operation.changeStock,
      moveMediumCost: operation.changeMidleCost,
      opercionEstadual: {
        naturalezaCombLubrificante: operation.stateOperations.naturalezaCombLubrificante,
        naturalezaItemIndustrializado: operation.stateOperations.naturalezaItemIndustrializado,
        naturalezaNFServicios: operation.stateOperations.naturalezaNFServicios,
        naturalezaNaoContribuinte: operation.stateOperations.naturalezaNaoContribuinte,
        naturalezaSTConsumidorCST10: operation.stateOperations.naturalezaSTConsumidorCST10,
        naturalezaSTConsumidorCST60: operation.stateOperations.naturalezaSTConsumidorCST60,
        naturalezaSubsTributariaCST10: operation.stateOperations.naturalezaSubsTributariaCST10,
        naturalezaSubsTributariaCST60: operation.stateOperations.naturalezaSubsTributariaCST60
      },
      opercionInterestadual: {
        naturalezaCombLubrificante: operation.interstateOperations.naturalezaCombLubrificante,
        naturalezaItemIndustrializado: operation.interstateOperations.naturalezaItemIndustrializado,
        naturalezaNFServicios: operation.interstateOperations.naturalezaNFServicios,
        naturalezaNaoContribuinte: operation.interstateOperations.naturalezaNaoContribuinte,
        naturalezaSTConsumidorCST10: operation.interstateOperations.naturalezaSTConsumidorCST10,
        naturalezaSTConsumidorCST60: operation.interstateOperations.naturalezaSTConsumidorCST60,
        naturalezaSubsTributariaCST10: operation.interstateOperations.naturalezaSubsTributariaCST10,
        naturalezaSubsTributariaCST60: operation.interstateOperations.naturalezaSubsTributariaCST60
      },
      priceReadjusts: {
        available: Boolean(operation.readjustPriceAtEntry),
        mediumCost: operation.readjustBy == "averageCost",
        lastCost: operation.readjustBy == "lastCost",
      },
      opDefault: operation.opDefault,
      superSimples: operation.superSimples,
      /** @TODO use as string */
      titleType: [operation.titleType ? operation.titleType._id : undefined],
    }

    apiObject = Utilities.removeNull(apiObject)

    return apiObject
  }

  private complyAPP(apiObject: ApiOperation): Operation {
    let operation: Operation = {
      updatedAt: apiObject.updatedAt,
      id: apiObject._id,
      COFINS: apiObject.COFINS,
      ICMS: apiObject.ICMS,
      PIS: apiObject.PIS,
      IPI: apiObject.IPI,
      CSLL: apiObject.CSLL,
      INSS: apiObject.INSS,
      IRPJ: apiObject.IRPJ,
      DRE: apiObject.DRE,
      available: apiObject.available,
      changeMidleCost: apiObject.moveMediumCost,
      changeStock: apiObject.moveStock,
      description: apiObject.description,
      entry: apiObject.inOut,
      generateFinancial: apiObject.financialGenera,
      opDefault: apiObject.opDefault, 
      docFiscal: apiObject.docFiscal,
      docFiscalServico: apiObject.docFiscalServico,
      interstateOperations: apiObject.opercionInterestadual,
      operationFunction: apiObject.function,
      descriptionFunction: apiObject.descriptionFunction,
      readjustPriceAtEntry: apiObject.priceReadjusts.available,
      readjustBy: apiObject.priceReadjusts.mediumCost ? "averageCost" : apiObject.priceReadjusts.lastCost ? "lastCost" : undefined,
      operationMessage: apiObject.Message,
      stateOperations: apiObject.opercionEstadual,
      superSimples: apiObject.superSimples,
      expenseTypeId: apiObject.expenseType,
      titleTypeId: apiObject.titleType[0],
    };
    return operation
  }


}


